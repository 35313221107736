export const usePricelist = (currency: Ref<"CZK" | "EUR" | "USD">) => {
  const { n } = useI18n();

  return computed(() => {
    const format = (price: number, hideCurrency = false) =>
      n(price, hideCurrency ? "credits" : `currency${currency.value}`);

    return {
      CZK: {
        tariffs: {
          extra: format(83333),
          basic: format(25000),
        },
        oneTime: {
          ultimate: {
            price: format(19999),
            hotJobs: format(30, true),
            boost: format(7, true),
            top3: format(7, true),
          },
          vip: {
            price: format(11999),
            hotJobs: format(30, true),
            boost: format(15, true),
          },
          extra30: {
            price: format(6749),
            hotJobs: format(30, true),
            boost: format(15, true),
          },
          extra14: {
            price: format(4749),
            hotJobs: format(14, true),
          },
          basic: {
            price: format(1999),
            discount500Credit: format(1499),
          },
          ppc: format(7000),
          ppcMinimal: format(5000),
          cpcMinimal: format(5000),
          top3: format(7999),
          boostOneTime: format(1499),
          copywritingPerOffer: format(2999),
          hotjobs: {
            biweekly: format(2999),
            monthly: format(4999),
          },
        },
        gold: {
          price: format(4166),
          startups: {
            month: format(2999),
            year: format(29999),
          },
          companies: {
            month: format(4999),
            year: format(49999),
          },
          discount: format(15, true),
          ultimate: {
            new: format(16999),
            discount: format(-3000),
          },
          vip: {
            new: format(10119),
            discount: format(-1800),
          },
          extra30: {
            new: format(5737),
            discount: format(-1012),
          },
          extra14: {
            new: format(4037),
            discount: format(-712),
          },
        },
        articles: {
          basic: format(29999),
          premium: format(54999),
        },
        ads: {
          vip: format(99999),
          extra: format(44999),
          basic: format(24999),
        },
        market: {
          vip: {
            monthly: format(2499),
            yearly: format(24999),
            top3Hp: format(2999),
          },
          extra: {
            monthly: format(999),
            yearly: format(9999),
            top3Hp: format(4999),
            top3Category: format(999),
          },
        },
        marketAddons: {
          b2bNewsletter: format(9999),
          performanceCampaign: format(5000),
          socialPr: format(2999),
          copywriting: format(4249),
          podcast: format(19999),
          prArticle: format(54999),
        },
        minCredits: format(5000, true),
        cashback: format(500, true),
        cashbackValue: format(500),
        startupProgramVoucher: format(4749),
        startupProgramCredits: {
          bundle: format(15000, true),
          price: format(13000),
        },
      },
      EUR: {
        tariffs: {
          extra: format(4169),
          basic: format(1250),
        },
        oneTime: {
          ultimate: {
            price: format(899),
            hotJobs: format(30, true),
            boost: format(7, true),
            top3: format(7, true),
          },
          vip: {
            price: format(499),
            hotJobs: format(30, true),
            boost: format(15, true),
          },
          extra30: {
            price: format(349),
            hotJobs: format(30, true),
            boost: format(15, true),
          },
          extra14: {
            price: format(249),
            hotJobs: format(14, true),
          },
          basic: {
            price: format(99),
            discount500Credit: format(69),
          },
          ppc: format(300),
          ppcMinimal: format(200),
          cpcMinimal: format(200),
          top3: format(349),
          boostOneTime: format(69),
          copywritingPerOffer: format(199),
          hotjobs: {
            biweekly: format(149),
            monthly: format(239),
          },
        },
        gold: {
          price: format(208),
          startups: {
            month: format(149),
            year: format(1499),
          },
          companies: {
            month: format(249),
            year: format(2499),
          },
          discount: format(15, true),
          ultimate: {
            new: format(764),
            discount: format(-135),
          },
          vip: {
            new: format(424),
            discount: format(-75),
          },
          extra30: {
            new: format(297),
            discount: format(-52),
          },
          extra14: {
            new: format(212),
            discount: format(-37),
          },
        },
        articles: {
          basic: format(1999),
          premium: format(2999),
        },
        ads: {
          vip: format(4999),
          extra: format(2499),
          basic: format(1299),
        },
        market: {
          vip: {
            monthly: format(149),
            yearly: format(2499),
            top3Hp: format(199),
          },
          extra: {
            monthly: format(49),
            yearly: format(499),
            top3Hp: format(249),
            top3Category: format(49),
          },
        },
        marketAddons: {
          b2bNewsletter: format(499),
          performanceCampaign: format(200),
          socialPr: format(199),
          copywriting: format(249),
          podcast: format(1499),
          prArticle: format(2999),
        },
        minCredits: format(500, true),
        cashback: format(20, true),
        cashbackValue: format(20),
        startupProgramVoucher: format(249),
        startupProgramCredits: {
          bundle: format(600, true),
          price: format(500),
        },
      },
      USD: {
        tariffs: {
          extra: format(4169),
          basic: format(1250),
        },
        oneTime: {
          ultimate: {
            price: format(899),
            hotJobs: format(30, true),
            boost: format(7, true),
            top3: format(7, true),
          },
          vip: {
            price: format(499),
            hotJobs: format(30, true),
            boost: format(15, true),
          },
          extra30: {
            price: format(349),
            hotJobs: format(30, true),
            boost: format(15, true),
          },
          extra14: {
            price: format(249),
            hotJobs: format(14, true),
          },
          basic: {
            price: format(99),
            discount500Credit: format(69),
          },
          ppc: format(300),
          ppcMinimal: format(200),
          cpcMinimal: format(200),
          top3: format(349),
          boostOneTime: format(69),
          copywritingPerOffer: format(199),
          hotjobs: {
            biweekly: format(149),
            monthly: format(239),
          },
        },
        gold: {
          price: format(208),
          startups: {
            month: format(149),
            year: format(1499),
          },
          companies: {
            month: format(249),
            year: format(2499),
          },
          discount: format(15, true),
          ultimate: {
            new: format(764),
            discount: format(-135),
          },
          vip: {
            new: format(424),
            discount: format(-75),
          },
          extra30: {
            new: format(297),
            discount: format(-52),
          },
          extra14: {
            new: format(212),
            discount: format(-37),
          },
        },
        articles: {
          basic: format(1999),
          premium: format(2999),
        },
        ads: {
          vip: format(4999),
          extra: format(2499),
          basic: format(1299),
        },
        market: {
          vip: {
            monthly: format(149),
            yearly: format(2499),
            top3Hp: format(199),
          },
          extra: {
            monthly: format(49),
            yearly: format(499),
            top3Hp: format(249),
            top3Category: format(49),
          },
        },
        marketAddons: {
          b2bNewsletter: format(499),
          performanceCampaign: format(200),
          socialPr: format(199),
          copywriting: format(249),
          podcast: format(1499),
          prArticle: format(2999),
        },
        minCredits: format(500, true),
        cashback: format(20, true),
        cashbackValue: format(20),
        startupProgramVoucher: format(249),
        startupProgramCredits: {
          bundle: format(600, true),
          price: format(500),
        },
      },
    }[currency.value];
  });
};
